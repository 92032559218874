<template>
  <div :class="['chapter-item', {'active': active}, {'deleted': deleted}]">
    <div class="chapter-item__meta">
      <div class="meta__year">{{detailDate(config.postDate)}}</div>
      <div class="meta__time" v-if="false">16:44</div>
      <div class="meta__btns">
        <template v-if="!deleted">
          <span role="button" class="text-primary chapter-action__edit"
                v-show="!active" @click="$emit('edit')">
            <i class="fa fa-pencil" aria-hidden="true"></i>{{$t('content.buttonEdit')}}
          </span>
          <span role="button" class="text-done chapter-action__save"
                v-show="active" @click="saveItemChange">
            <i class="fa fa-check" aria-hidden="true"></i>{{$t('content.buttonSave')}}
          </span>
          <span role="button" class="chapter-action__delete"
                v-show="!active" @click="$emit('delete')">
            <i class="fa fa-trash-o" aria-hidden="true"></i>{{$t('content.buttonDelete')}}
          </span>
        </template>
        <template v-else>
          <span role="button" class="text-primary chapter-action__recover" @click="$emit('recover')">
            <i class="fa fa-reply" aria-hidden="true"></i>{{$t('content.buttonRecover')}}
          </span>
        </template>
      </div>
    </div>
    <div class="chapter-item__content" v-html="contentParser(config.content)" v-show="!active">
    </div>
    <textarea class="textarea chapter-item__content" v-auto
              v-if="active" v-model="config.content"></textarea>
    <div class="chapter-item__albums">
      <draggable :list="images" class="albums-action" draggable=".album-image-active">
        <div :class="['album-image', {'deleted': image[2], 'album-image-active': active}]"
             v-for="(image, index) in images" :key="image[0]"
             @click="previewImage(image, index)">
          <div class="uploading-item" :data-orient="image[4]"
               v-lazy:background-image="getImageUrl(image[1], 320, false)"></div>
          <p v-if="image[2]">已删除</p>
          <div class="album-image__action" v-show="active" role="button">
            <i class="fa fa-times" v-if="!image[2]" aria-hidden="true"></i>
            <i class="fa fa-reply" v-if="image[2]"></i>
          </div>
        </div>
        <div class="album-image uploading"
             v-for="image in tempImages" :key="image.timestamp">
          <div class="uploading-item" :data-orient="image.rotate"
               :style="{backgroundImage: 'url(' + image.image + ')'}"></div>
          <block :done="image.dataLoaded"></block>
        </div>
        <div class="album-image uploader" v-show="active">
          <uploader @change="uploadImage" :mixed="true"/>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import calculateNodeHeight from 'src/_utils/calculateNodeHeight'
import { findIndex, last } from 'lodash'
import api from 'src/api'
import { getImageUrl } from 'src/_utils/'
import EXIF from 'exif-js'
import Block from 'src/components/common/LoadingBlock.vue'
import draggable from 'vuedraggable'

export default {
  name: 'ChapterItem',
  components: {
    Block,
    draggable
  },
  props: {
    config: {
      type: Object,
      default: function () {
        return {
          title: '标题',
          content: '内容',
          type: 'status', // 内容状态
          postDate: '',
          createdAt: '',
          deleted: false,
          pics: {
            current: [],
            deleted: []
          },
          images: []
        }
      }
    },
    active: {
      type: Boolean,
      default: false
    },
    deleted: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      images: [],
      tempImages: []
    }
  },
  directives: {
    auto: {
      inserted (el) {
        calculateNodeHeight(el)
      },
      update (el) {
        calculateNodeHeight(el)
      }
    }
  },
  created () {
    const {
      current,
      deleted
    } = this.config.pics
    current.map(pic => pic.push(false))

    deleted.map(pic => pic.push(true))
    this.images = current.concat(deleted)
  },
  watch: {
    active (val, oldVal) {
      if (this.saving && oldVal) {
        this.saveItemChange()
      }
    }
  },
  methods: {
    getImageUrl,
    contentParser (html) {
      return html.replace(/\n/g, '<br>')
    },
    detailDate (date) {
      return this.$moment(date).format('YYYY/MM/DD HH:mm')
    },
    // 预览图片，使用全局加载 Vue.$viewer({})
    previewImage (image, index) {
      if (this.active) {
        this.deleteImage(index, image[2])
      } else {
        let images = this.images.slice()
        images = images.map(item => this.getImageUrl(item[1], 640))
        this.$viewer({
          images,
          activeIndex: index
        })
      }
    },
    async uploadImage (files) {
      if (this.tempImages.length) {
        this.$toast({
          message: '当前存在上传内容'
        })
        return
      }

      console.time('处理图片')
      const albums = []
      for (const image of files) {
        let temImage
        if (image.source) { // 可预览图片
          temImage = await this.readImageExif(image)
        } else {
          temImage = {
            source: image,
            rotate: 0
          }
        }
        albums.push(temImage)
      }
      console.timeEnd('处理图片')

      const images = []
      albums.map((file, index) => {
        const timestamp = window.btoa(Date.now() + index * 100)
        const form = new FormData()
        images.push({ ...file, ...{ timestamp } })
        form.append('image', file.source)
        api.uploadImage(form).then(data => {
          if (!data || data.errors) {
            this.$toast({
              message: '圖片上傳失敗，請重試',
              position: 'bottom'
            })
            return
          }
          const imgIndex = findIndex(this.tempImages, { timestamp })
          let {
            origin,
            hostname
          } = window.location

          if (/localhost|m\.ipastbook/g.test(hostname)) {
            origin = 'https://ipastbook.com'
          }
          data.image = /http?s:/.test(data.image) ? data.image : `${origin}${data.image}`
          const img = [data.imageId, data.image, false, timestamp, file.rotate]
          if (file.image) {
            img[1] = file.image
          }

          this.tempImages.splice(imgIndex, 1)

          // 图片上传顺序，按上传时间排序，成功之后拿当前图片去排序
          const arr = this.images.slice()
          const lastImages = last(arr) || []
          if (lastImages[3]) {
            for (const item in arr) {
              const image = arr[item]
              if (image[3]) { // 当前存在上传信息
                if (window.atob(image[3]) > window.atob(timestamp)) { // 元素
                  this.images.splice(item, 0, img)
                  break
                } else {
                  if (Number(item) === arr.length - 1) {
                    this.images.splice(item + 1, 0, img)
                    break
                  }
                }
              }
            }
          } else {
            this.images.push(img)
          }
        }, (e) => {
          if (e.timeout) {
            const imgIndex = findIndex(this.tempImages, { timestamp })
            this.tempImages.splice(imgIndex, 1)
          }
        })
      })
      this.tempImages = this.tempImages.concat(images)
    },
    readImageExif (image) {
      return new Promise((resolve, reject) => {
        try {
          EXIF.getData(image.source, function () {
            const allMetaData = EXIF.getAllTags(this)
            image.rotate = allMetaData.Orientation || 0
            resolve(image)
          })
        } catch (e) {
          console.error('图片信息处理失败', e)
          image.rotate = 0
          resolve(image)
        }
      })
    },
    deleteImage (index, imageStatus) {
      // console.log(index, imageStatus)
      this.$set(this.images[index], 2, !imageStatus)
    },
    saveItemChange () {
      if (this.tempImages.length) {
        this.$toast({
          message: '當前存在上傳內容，請稍後重試'
        })
        return
      }
      const selectedPics = [] // 保存的图片 id 列表
      this.images.map(image => {
        if (!image[2]) {
          selectedPics.push(image[0])
        }
      })
      const content = this.config.content
      const title = this.config.title
      const data = {
        content,
        title,
        selectedPics
      }
      this.$emit('save', data)
    }
  }
}
</script>

<style lang="scss">
.chapter-item {
  border-radius: 4px;
  background-color: #fff;
  padding: 1rem;
  font-size: 14px;
  margin-bottom: 1rem;
  border: 1px solid #DFDFDF;

  &.deleted {
    background-color: #e9e9e9;
  }

  .chapter-item__meta {
    color: #999;
    display: flex;
    font-weight: 500;
  }

  .meta__year {
    margin-right: 1rem;
  }

  .meta__btns {
    margin-left: auto;

    [role=button] {
      margin-left: 1rem;

      i {
        margin-right: .5rem;
      }
    }
  }

  .chapter-item__content {
    margin: 1rem 0;
    font-size: inherit;
    max-height: 100%;
    word-wrap: break-word;

    &.textarea {
      transition: all .3s, height 0s;
      min-height: 72px;
    }

    iframe {
      width: 100%;
    }

    & > * {
      width: 100%;
    }
  }

  .uploading-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: {
      size: cover;
      repeat: no-repeat;
      position: 50%;
    }
  }

  .chapter-item__albums {
    width: 100%;
    display: flex;
    flex-flow: wrap;

    .albums-action {
      flex: 1;
      display: flex;
      flex-flow: wrap;
    }

    .album-image {
      cursor: zoom-in;
      width: 110px;
      height: 110px;
      background-color: #eee;
      margin-right: 3%;
      margin-bottom: 1rem;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      background: {
        size: cover;
        repeat: no-repeat;
        position: 50%;
      }

      &[lazy=loading] {
        background-size: 48px;
      }

      .album-image__action {
        position: absolute;
        top: 0;
        right: 0;
        width: 2.75rem;
        height: 2.75rem;
        line-height: 2rem;
        padding: 0 0 .75rem .75rem;
        background-color: rgba(183, 183, 183, 0.9);
        border-radius: 0 0 0 100px;
        text-align: center;
        color: white;
        font-size: 1.25rem;
        z-index: 5;
      }

      p {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        margin-top: -0.5rem;
        font-size: 1rem;
        text-align: center;
        color: white;
        z-index: 3;
      }

      &.deleted {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 2;
        }
      }

      &.uploader {
        border: 2px dashed #b7b7b7;
        background: {
          color: white;
          size: auto;
          image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEUAAADd3d3swgqXAAAAAXRSTlMAQObYZgAAABZJREFUCFtjZPggwIgP/2f4iIIJqAcAuP0X1ZQ/+6oAAAAASUVORK5CYII=);
          repeat: no-repeat;
          position: 50%;
        };

        label {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
